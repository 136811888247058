




















import { Component, Prop } from "vue-property-decorator";
import { Document } from "@/models/document";
import AttachmentRow from "@/components/attachments/AttachmentRow.vue";
import AttachmentList from "@/components/attachments/AttachmentList.vue";
import { Attachment } from "@/components/attachments/AttachmentListTypes";
import { attachmentsApi } from "@/api/portal/attachments";
import TranslatableComponent from "@/lib/mixins/translateable";

@Component({
  components: { AttachmentList, AttachmentRow },
})
export default class DocumentAttachments extends TranslatableComponent {
  @Prop()
  private document!: Document;

  get attachments(): Array<Attachment> {
    return this.document?.attachments.map((attachment) => {
      return {
        id: attachment.id,
        contentType: attachment.contentType,
        name: attachment.fileName,
        size: attachment.size,
        download: () =>
          attachmentsApi.get(
            this.document.id,
            attachment.id,
            attachment.contentType
          ),
        preview: (contentType: string) =>
          attachmentsApi.preview(this.document.id, attachment.id, contentType),
      } as Attachment;
    });
  }
}
